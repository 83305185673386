// create a Link component wrapping the Next.js Link component
import { useUserSessionContext } from "hooks/useUserSessionContext";
import NextLink, { LinkProps } from "next/link";
import React from "react";
import { getPathNameWithStoreType } from "utils/misc";

interface CustomLinkProps extends LinkProps {
  href: string;
  children: any;
  target?: string;
}

const Link: React.FC<CustomLinkProps> = ({ href, children, ...props }) => {
  const { storeType }  = useUserSessionContext();

  return (
    <NextLink 
      href={getPathNameWithStoreType(href, null, storeType)}
      {...props}
    >
      {React.isValidElement(children) ? React.cloneElement(children) : <>{children}</>}
    </NextLink>
  );
};

export default Link;
